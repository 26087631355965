const {cs} = require("../../../../../common/react/chain-services");
const {Load} = require("../../../../../common/react/load");
const {arrMapToO} = require("../../../../../common/utils/objects");

const FbSdk = ({appId, next}) => cs(
    ["fbSdk", ({}, next) => Load({
        fetch: () => new Promise((resolve, reject) => {
            window.fbAsyncInit = function() {
                delete window.fbAsyncInit;

                FB.init({
                    appId,
                    cookie     : true,
                    xfbml      : true,
                    version    : 'v6.0'
                });

                FB.AppEvents.logPageView();

                resolve(FB);
            };

            (function(d, s, id){
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {return;}
                js = d.createElement(s); js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        }),
        next,
    })],
    ({fbSdk}) => next(fbSdk && arrMapToO(
        ["api", "login", "logout", "getLoginStatus"],
        (m) => promisify(fbSdk[m])
    ))
);
exports.FbSdk = FbSdk;

const promisify = (fn) => (...args) => new Promise((resolve, reject) => {
    fn(...args, resolve);
});
