const {Component, createElement: h} = require("react");

const UseState = ({next, getInitValue, initValue}) => h(UseState1, {next, getInitValue, initValue});
exports.UseState = UseState;
exports.UseState1 = UseState;

class UseState1 extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            value: props.getInitValue ? props.getInitValue() : props.initValue,
        };
    }
    componentWillUnmount() {
        this.unmounted = Date.now();
    }

    setState(state, callback) {
        if (!this.unmounted || Date.now() - this.unmounted > 3000) {
            super.setState(state, callback);
            return;
        }
    }

    render() {
        const {next} = this.props;
        const {value} = this.state;

        return next({
            value,
            onChange: (value1, cb) => this.setState({value: value1}, cb),
            change: (reduceValue, cb) => {
                this.setState(
                    (state) => ({
                        ...state,
                        value: reduceValue(state.value),
                    }),
                    cb
                );
            },
        }) || null;
    }
}
//
// console.log(23123)
// console.log(Object.getPrototypeOf(UseState1) === Component)
// console.log(Object.getPrototypeOf(() => {}) === Object.getPrototypeOf(() => {}))
// console.log(Object.getPrototypeOf(() => {}) === Object.getPrototypeOf(UseState1))
// // console.log(function)