const {Watch} = require("./watch");
const {cLsJson} = require("./ls-json");
const {cs} = require("./chain-services");
const {createElement: h, Fragment} = require("react");
const {UseState} = require("./use-state");

const StorageState = ({key, defaultValue, next}) => cs(
    ["state", (_, next) => UseState({
        getInitValue: () => ({
            key,
            value: cLsJson(key).get() || defaultValue,
        }),
        next,
    })],
    ({state}) => h(Fragment, {},

        h(Watch, {
            value: key,
            onChanged: () => {
                state.onChange({
                    key,
                    value: cLsJson(key).get() || defaultValue,
                });
            },
        }),

        next({
            value: state.value.key === key ? state.value.value : cLsJson(key).get() || defaultValue,
            onChange: (value) => {
                cLsJson(key).set(value);
                return state.onChange({key, value});
            },
        }),
    ),
);
exports.StorageState = StorageState;
