
import {cs} from "../../../../../../common/react/chain-services";import * as React from "react";
import "./ready-overlay.scss";

export const ReadyOverlay = ({onCancel}) => cs(
    ({}) => (
        <div className="ready-overlay-35j">

            <div className="box">
                <div className="title">
                    Get ready
                </div>

                <div className="instruction">
                    Get yourself into the screen, with both shoulders and knees visible
                </div>

                <button onClick={onCancel}>Cancel</button>
            </div>
        </div>
    )
);
