const React = require("react");

const Watch = ({initRun=false, next=undefined, value, onChanged}) => React.createElement(Watch1, {
    initRun, next, onChanged, value,
});
exports.Watch = Watch;

class Watch1 extends React.Component {

    constructor(props, context) {
        super(props, context);

        if (props.initRun) {
            props.onChanged(this.props.value, undefined);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.props.onChanged(this.props.value, prevProps.value);
        }
    }

    render() {
        const {next} = this.props;

        return next ? next() : null;
    }
}
