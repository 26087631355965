const {cMapApiData} = require("./map-api-data");

const createApiInvoker = (
    {
        host="",
        getUrl=({host, methodName}) => `${host}/api/${methodName}`,
        getHeaders
    }={}
) => new Proxy({}, {
    get: (override, methodName) => {
        if (override.hasOwnProperty(methodName)) {
            return override[methodName];
        }

        const {mapRequest, parseRes} = cMapApiData({});
        return async (data) => {
            const {body, headers} = await mapRequest(data);

            return await fetch(
                getUrl({host, methodName}),
                {
                    method: "POST",
                    body,
                    credentials: "include",
                    headers: !getHeaders ? headers : getHeaders(headers),
                }
            )
                .then(parseRes)
                ;
        };
    },
});
exports.createApiInvoker = createApiInvoker;
