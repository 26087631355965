const {TimedRefresh} = require("./timed-refresh");
const {FComponent} = require("./f-component");
const {cs} = require("./chain-services");
const {createElement: h} = require("react");

const TimedAction = ({time, props, skippable, action}) => cs(
    ({}, next) => h(TimedRefresh, {
        delay: 24*60*60*1000,
        next,
    }),
    ({}) => time - Date.now() < 24 * 60*60*1000 && h(TimedAction1, {time, props, skippable, action}),
)
exports.TimedAction = TimedAction;

class TimedAction1 extends FComponent {
    constructor(props, context) {
        super(props, context);

        this.onMount(() => {
            this.setupTimeout();
            this.onUnmount(() => {
                this.clear && this.clear();
            });
        });
    }

    setupTimeout() {
        this.clear && this.clear();
        this.clear = null;

        let delay = this.props.time - Date.now();
        if (delay<0) {
            if (!this.props.skippable) {
                delay = 0;
            }
        }

        if (delay < 0) {
            return;
        }

        const timeout = setTimeout(() => {
            this.clear = null;

            this.props.action({
                getLatestProps: () => this.props.props,
            });
        }, delay);
        this.clear = () => clearTimeout(timeout);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.time !== this.props.time) {
            this.setupTimeout();
        }
    }
    render() {
        return null;
    }
}
