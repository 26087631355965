const {TimeoutAction} = require("../../../../../common/react/timeout-action");
const {spc} = require("../../../../../common/react/state-path-change");
const {Invoke} = require("../../../../../common/react/invoke");
const {cs} = require("../../../../../common/react/chain-services");
const {UseState} = require("../../../../../common/react/use-state");
const {Fragment, createElement: h} = require("react");
const {sum} = require("../../../../../common/utils/collections");

const TrackProgress = ({tfPose, progress, next}) => cs(
    ["started", (_, next) => UseState({
        // initValue: true,
        next,
    })],
    ["allRevealed", (_, next) => next(tfPose.pose && !requiredParts.find((part) => {
        const kp = tfPose.pose.keypoints.find((p) => p.part === part);
        return kp.score < 0.6;
    }))],
    ["posingState", (_, next) => UseState({next})],
    ({started, allRevealed, posingState}) => {

        const poseProgress = !started.value || !tfPose.prediction ? null : getProgress(tfPose.prediction, squat);

        return h(Fragment, {},
            !started.value && allRevealed && (
                h(Invoke, {fn: () => started.onChange(true)})
            ),

            poseProgress != null ? h(Fragment, {},
                poseProgress > 0.8 && posingState.value == null && (
                    h(Invoke, {fn: () => posingState.onChange({down: true})})
                ),
                poseProgress < 0.25 && posingState.value != null && (
                    h(Invoke, {
                        fn: () => {
                            posingState.onChange(null);
                            // console.log("UP");
                            spc(progress, ["squat", "count"], (c) => (c||0) + 1);
                        },
                    })
                ),
            ) : posingState.value != null && (
                h(TimeoutAction, {
                    action: () => {
                        posingState.onChange(null);
                    },
                })
            ),

            next({
                started: started.value,
                invalid: poseProgress == null,
                poseProgress,
                posingState: posingState.value,
                count: progress.value?.["squat"]?.count || 0,
            }),
        );
    },
);
exports.TrackProgress = TrackProgress;

const squat = {
    validPoses: [
        {
            name: "up",
            weight: 0,
        },
        {
            name: "middle",
            weight: 0.5,
        },
        {
            name: "down",
            weight: 1,
        },
    ],
    invalidPoses: ["idle", "resting"],
};

const getProgress = (prediction, exercise) => {
    if (exercise.invalidPoses.find((poseName) => prediction.find((p) => p.className === poseName && p.probability > 0.6))) {
        return null;
    }

    return sum(exercise.validPoses, (pose) => {
        const p = prediction.find((p) => p.className === pose.name);
        return p.probability * pose.weight;
    });
};

const requiredParts = [
    "leftShoulder",
    "rightShoulder",
    "leftKnee",
    "rightKnee",
];

/*
0: {score: 0.9978935122489929, part: "nose", position: {…}}
1: {score: 0.9991753697395325, part: "leftEye", position: {…}}
2: {score: 0.998047947883606, part: "rightEye", position: {…}}
3: {score: 0.9887374639511108, part: "leftEar", position: {…}}
4: {score: 0.3742913007736206, part: "rightEar", position: {…}}
5: {score: 0.8829174041748047, part: "leftShoulder", position: {…}}
6: {score: 0.95998215675354, part: "rightShoulder", position: {…}}
7: {score: 0.02417602576315403, part: "leftElbow", position: {…}}
8: {score: 0.06548389047384262, part: "rightElbow", position: {…}}
9: {score: 0.01650196872651577, part: "leftWrist", position: {…}}
10: {score: 0.008054198697209358, part: "rightWrist", position: {…}}
11: {score: 0.013513416051864624, part: "leftHip", position: {…}}
12: {score: 0.025444308295845985, part: "rightHip", position: {…}}
13: {score: 0.002688310109078884, part: "leftKnee", position: {…}}
14: {score: 0.0018745650304481387, part: "rightKnee", position: {…}}
15: {score: 0.0005212459363974631, part: "leftAnkle", position: {…}}
16: {score: 0.0005429026787169278, part: "rightAnkle", position: {…}}
 */
