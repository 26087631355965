const {cache0} = require("../../../../../common/utils/cache0");
const {cs} = require("../../../../../common/react/chain-services");
const {Load} = require("../../../../../common/react/load");
const {Fragment, createElement: h} = require("react");
const {Invoke} = require("../../../../../common/react/invoke");
const {UseState} = require("../../../../../common/react/use-state");

const tmPose = window["tmPose"];

export const TfPose = ({getCanvas, size = 200, delay, autoStart, next}) => cs(
    ["integration", ({}, next) => Load({
        fetch: async () => {
            const URL = "./model/";

            const modelURL = URL + "model.json";
            const metadataURL = URL + "metadata.json";

            const model = await tmPose.load(modelURL, metadataURL);

            return {
                model,
            };
        },
        next,
    })],
    ["active", (_, next) => UseState({
        initValue: autoStart != null ? !!autoStart : true,
        next,
    })],
    ["state", (_, next) => UseState({next})],

    ({integration, state, active}, next) => h(Fragment, {},
        integration && active.value && (
            h(Invoke, {
                props: {delay},
                fn: async ({isMounted, getLatestProps}) => {

                    const webcam = await (async () => {
                        // Convenience function to setup a webcam
                        const flip = true; // whether to flip the webcam
                        const webcam = new tmPose.Webcam(size, size, flip); // width, height, flip
                        try {
                            await webcam.setup(); // request access to the webcam
                            await webcam.play();
                            return webcam;
                        } catch (e) {
                            return null;
                        }
                    })();

                    if (!webcam) {
                        active.onChange(false);
                        return;
                    }

                    const ctx = (() => {
                        const canvas = getCanvas();
                        canvas.width = size; canvas.height = size;
                        return canvas.getContext("2d");
                    })();


                    const {model} = integration;

                    const loop = async (timestamp) => {
                        if (isMounted()) {
                            webcam.update(); // update the webcam frame
                            await predict();

                            // console.log(getLatestProps());
                            const {delay} = getLatestProps();
                            if (!delay) {
                                window.requestAnimationFrame(loop);
                            } else {
                                setTimeout(() => {
                                    window.requestAnimationFrame(loop);
                                }, delay);
                            }
                        } else {
                            webcam.stop();
                        }
                    };

                    window.requestAnimationFrame(loop);

                    const log = cache0((pose) => console.log(pose.keypoints));

                    async function predict() {
                        // Prediction #1: run input through posenet
                        // estimatePose can take in an image, video or canvas html element
                        const { pose, posenetOutput } = await model.estimatePose(webcam.canvas);
                        // Prediction 2: run input through teachable machine classification model
                        const prediction = await model.predict(posenetOutput);

                        state.onChange({prediction, pose});

                        // if (pose) {
                        //     log(pose);
                        // }

                        // finally draw the poses
                        drawPose(pose);
                    }

                    function drawPose(pose) {
                        if (webcam.canvas) {
                            ctx.drawImage(webcam.canvas, 0, 0);
                            // draw the keypoints and skeleton
                            if (pose) {
                                const minPartConfidence = 0.5;

                                tmPose.drawKeypoints(pose.keypoints, minPartConfidence, ctx);
                                tmPose.drawSkeleton (pose.keypoints, minPartConfidence, ctx);

                                // console.log(pose.keypoints)
                            }
                        }
                    }
                },
            })
        ),
        next(),
    ),

    ({state, active}) => next({
        active: active.value,
        ...state.value,

        stop: () => {
            active.onChange(false);
        },
        start: () => {
            active.onChange(true);
        },
    }),
);