const parseJsonResp = (resp, onFail) => {
    if (resp && resp.status === 401) {
        if (onFail) {
            onFail && onFail();
            return new Promise(() => {}); // Empty promise
        } else {
            return Promise.reject(401);
        }
    } else if (resp && resp.status === 404) {
        console.warn("API Not found", resp);
        return Promise.reject(404);
    } else if (resp && resp.status === 405) {
        console.warn("API Wrong version", resp);
        window.location.reload(true);
        return new Promise(() => {}); // Empty promise
    } else if (resp && resp.status === 500) {
        console.error("Internal server error", resp);
        return new Promise((resolve, reject) => reject("Internal server error")); // Empty promise
    } else {
        if (resp.headers.get("content-length") === "0") {
            return null;
        }

        return resp.json();

        // const contentType = resp.headers.get("content-type");
        // if (contentType != null && contentType.startsWith("application/json")) {
        //     return resp.json();
        // } else {
        //     return resp.blob();
        // }

    }
};
exports.parseJsonResp = parseJsonResp;
