import React, {createElement as h, Fragment} from "react";
import {cs} from "../../../../../common/react/chain-services";
import {TfPose} from "./tf-pose";
import {TrackProgress} from "./track-progress";
import {cx} from "emotion";
import "./squat-counter-app.scss";
import {ReadyOverlay} from "./ready-overlay/ready-overlay";
import {VerticalProgress} from "./vertical-progress/vertical-progress";
import {Invoke} from "../../../../../common/react/invoke";
import {UseState} from "../../../../../common/react/use-state";
import {TimeoutAction} from "../../../../../common/react/timeout-action";
import {Watch} from "../../../../../common/react/watch";
import {Auth} from "./auth";
import {StorageState} from "../../../../../common/react/storage-state";
import {formatDateS} from "../../../../../common/formats/format-date-s";
import {paddingLeft} from "../../../../../common/utils/strings";
import {Load2} from "../../../../../common/react/load2";

export const SquatCounterApp = ({}) => cs(
    ["auth", ({}, next) => Auth({next})],
    ({auth}, next) => !auth.initialized ? ("Loading auth...") : next(),

    ({auth}, next) => <Fragment key={auth.accountId}>{next()}</Fragment>,

    ["progress", ({auth}, next) => auth.apis ? (
        cs(
            ["data", ({}, next) => Load2({
                _key: formatDateS(new Date()),
                fetch: () => auth.apis.getDailyProgress({date: formatDateS(new Date()),}),
                next,
            })],
            ({data}, next) => data.loading ? "Loading progress..." : next(),
            ({data}) => next({
                ...data,
                onChange: async (v) => {
                    data.onChange(v);
                    await auth.apis.saveDailyProgress({date: formatDateS(new Date()), progress: v});
                },
            }),
        )
    ) : (
        StorageState({
            key: "daily-progress." + formatDateS(new Date()),
            defaultValue: {},
            next,
        })
    )],

    ["performanceMode", (_, next) => UseState({initValue: "powerful", next})], // powerful|energySaving
    ["tfPose", ({performanceMode}, next) => TfPose({
        autoStart: !DEV_QUAN,
        // size: 700,
        delay: performanceMode.value === "powerful" ? null : 700,
        getCanvas: () => document.getElementById("canvas-354y"),
        next,
    })],
    ["trackProgress", ({tfPose, progress}, next) => TrackProgress({
        progress,
        tfPose,
        next,
    })],

    ({performanceMode, trackProgress}, next) => controlPerformance({performanceMode, trackProgress, next}),

    ["cancelled", (_, next) => UseState({
        initValue: DEV_QUAN,
        next,
    })],
    ({trackProgress, performanceMode, tfPose, auth, cancelled}) => (
        <div className="squat-counter-app">

            {!trackProgress.started && !cancelled.value && (
                <ReadyOverlay {...{
                    onCancel: () => {
                        tfPose.stop();
                        cancelled.onChange(true);
                    },
                }}/>
            )}

            <Watch {...{
                value: trackProgress.count,
                onChanged: async (newVal, oldVal) => {
                    if (newVal === oldVal + 1) {
                        await beep({vol: .3});
                    }
                },
            }}/>
            <Watch {...{
                value: trackProgress.posingState,
                onChanged: async (newVal, oldVal) => {
                    if (newVal) {
                        await beep({vol: .15, freq: 260});
                    }
                },
            }}/>

            <div className={cx("main")}>
                <div className="canvas">
                    <canvas id="canvas-354y"/>
                </div>
            </div>

            <div className="info">

                {auth.fb_id && (
                    <img src={`https://graph.facebook.com/${auth.fb_id}/picture?type=square`}/>
                )}

                {!auth.accountId ? (
                    <button
                        onClick={auth.loginFb}
                    >Login FB</button>
                ) : (
                    <button
                        onClick={auth.logout}
                    >Logout</button>
                )}


                <div className="count">
                    {trackProgress.count}
                </div>

                <div className="status">
                    <VerticalProgress {...{
                        value: trackProgress.poseProgress,
                    }}/>

                    {trackProgress.posingState && (
                        <i className="fa fa-check-circle"/>
                    )}
                </div>

                <div className="debug1">
                    <div className="">
                        {performanceMode.value === "powerful" ? "active" : "idle"}
                    </div>

                    <div className="">
                        {trackProgress.poseProgress?.toFixed(2) || "--"}
                    </div>
                </div>

                <div className="debug2">
                    {tfPose.prediction?.map((p, i) => (
                        <div className="" key={i}>
                            {p.className + ": " + p.probability.toFixed(2)}
                        </div>
                    ))}
                </div>

                <div className="control">
                    {tfPose.active ? (
                        <button
                            onClick={tfPose.stop}
                        >Stop</button>
                    ) : (
                        <button
                            onClick={tfPose.start}
                        >Start</button>
                    )}
                </div>


            </div>
        </div>
    )
);

const controlPerformance = ({performanceMode, trackProgress, next}) => (<>
    {performanceMode.value === "powerful" && trackProgress.invalid && (
        h(TimeoutAction, {delay: 2000, action: () => performanceMode.onChange("energySaving")})
    )}
    {performanceMode.value === "energySaving" && !trackProgress.invalid && (
        h(Invoke, {fn: () => performanceMode.onChange("powerful")})
    )}

    {next()}
</>);

const beep = ({freq = 520, duration = 100, vol = 1}) => {
    const context = new AudioContext();
    const oscillator = context.createOscillator();
    const gain = context.createGain();
    oscillator.connect(gain);
    oscillator.frequency.value = freq;
    oscillator.type = "square";
    gain.connect(context.destination);
    gain.gain.value = vol;
    oscillator.start(context.currentTime);
    oscillator.stop(context.currentTime + duration * 0.001);
}
