const {createApiInvoker} = require("../../../../../common/api/api-invoker");

const {FbSdk} = require("../common/fb-sdk");
const {createElement: h, Fragment} = require("react");

const {cs} = require("../../../../../common/react/chain-services");
const {Invoke} = require("../../../../../common/react/invoke");
const {UseState} = require("../../../../../common/react/use-state");

const Auth = ({next}) => cs(
    ["fb", ({}, next) => FbSdk({
        appId: "825814177909022",
        next,
    })],
    ["state", (_, next) => UseState({
        initValue: {initialized: false},
        next,
    })],
    ({fb, state}) => h(Fragment, {},
        next({
            ...state.value,
            fb,
            apis: state.value.accountId && createApiInvoker({
                getUrl: ({methodName}) => `/auth-api/${methodName}`,
                getHeaders: (headers) => ({...headers, account_id: state.value.accountId}),
            }),
            loginFb: async () => {

                const loginRes = await fb.login();

                if (loginRes.authResponse) {
                    const accRes = await fb.api('/me');
                    // console.log(accRes) // name, id

                    const accountId = await createApiInvoker().getAccountByFbId(accRes.id);

                    state.onChange({
                        accountId,
                        fb_id: accRes.id,
                        initialized: true,
                    });
                }
            },
            logout: async () => {
                await fb.logout();
                state.onChange({
                    ...state.value,
                    fb_id: null,
                    accountId: null,
                });
            },
        }),

        fb && h(Invoke, {
            fn: async () => {
                const res = await fb.getLoginStatus();

                if (res.authResponse) {
                    const accountId = await createApiInvoker().getAccountByFbId(res.authResponse.userID);
                    state.onChange({
                        accountId,
                        fb_id: res.authResponse.userID,
                        initialized: true,
                    });
                } else {
                    state.onChange({
                        initialized: true,
                    });
                }
            },
        })
    ),
);
exports.Auth = Auth;
