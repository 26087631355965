const {parseBlobResp} = require("./parse-blob-resp");
const {parseJsonResp} = require("./parse-json-resp");
const {omit} = require("../utils/objects");

const {promisify} = require("util");

const parseResNode = (res) => new Promise((resolve) => {
    res.setEncoding('utf8');
    let data = "";
    res.on('data', (chunk) => {
        data += chunk;
    });
    res.on('end', () => {
        resolve(data.length ? JSON.parse(data) : null);
    });
});

const cMapApiData = ({acceptFile, largeString, resLargeString, rawResponse} = {}) => {
    const isClientSide = typeof window !== "undefined";
    const parseRes = resLargeString ? parseResNodeLargeString(resLargeString) : rawResponse ? parseBlobResp : isClientSide ? parseJsonResp : parseResNode;

    if (largeString) {
        return {
            mapRequest: async (data) => {
                const dump = data[largeString];
                const body = typeof dump === "string" ? Buffer.from(dump, "utf8") : dump;
                // console.log(body)
                // console.log("Content-length", body.length)
                return {
                    body,
                    headers: {
                        "json-body": JSON.stringify(omit(data, [largeString])),
                        "accept-encoding": "gzip",
                        "Content-length": body.length,
                    },
                };
            },
            parseRes,
        };
    } else if (acceptFile) {
        return {
            mapRequest: async (data) => {
                // console.log("acceptFile", acceptFile);
                const fs = require("fs");
                const readFileAsync = promisify(fs.readFile);
                const {attr} = acceptFile;
                return {
                    body: await readFileAsync(data[attr]),
                    headers: {
                        "json-body": JSON.stringify(omit(data, [attr])),
                        "accept-encoding": "gzip",
                    },
                    parseRes,
                };
            },
            parseRes,
        };
    } else {

        return {
            mapRequest: async (data) => {
                return {
                    body: data && JSON.stringify(data),
                    headers: {
                        "content-type": "application/json",
                        "accept-encoding": "gzip",
                    },
                    parseRes,
                };
            },
            parseRes,
        };
    }
};
exports.cMapApiData = cMapApiData;

const parseResNodeLargeString = (asAttr) => (res) => new Promise((resolve, reject) => {
    const {ungzip} = module["REQUIRE".toLowerCase()]("node-gzip");
    const others = JSON.parse(res.headers["json-body"]);
    // res.setEncoding('utf8');
    let data = [];
    res.on('data', (chunk) => {
        data.push(chunk);
    });
    res.on('end', async () => {
        const buffer = Buffer.concat(data);

        // console.log(`Got buffer ${formatBigNumber(buffer.length/1000)}kb`);

        resolve({
            ...others,
            [asAttr]: await ungzip(buffer),
        });
    });
});