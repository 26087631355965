import * as React from "react";
import "./vertical-progress.scss";

export const VerticalProgress = ({value}) => (
    <div className="vertical-progress-35h">
        <div className="main">
            {value != null && (
                <div className="bar" style={{
                    top: `${Math.round(value*100)}%`,
                    // backgroundColor: color,
                }}/>
            )}
        </div>
    </div>
);